import { LucideProps } from 'lucide-react'

const PhoneTransparent = (props: LucideProps) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.26849 2.53635C7.05113 1.51611 5.23731 1.66276 4.31363 2.95495C4.06693 3.30008 3.77458 3.71175 3.26962 4.3434C1.53255 6.51629 1.53256 9.66257 3.27922 11.8278C4.56863 13.4261 5.92057 15.039 7.43187 16.5503C8.94317 18.0615 10.556 19.4134 12.1544 20.7029C14.3196 22.4496 17.4659 22.4496 19.6387 20.7125C20.3126 20.1737 20.7331 19.8802 21.09 19.6253C22.3382 18.7339 22.5131 16.9854 21.5385 15.8011C20.7683 14.8652 19.9106 13.9724 19.0833 13.1008C18.4243 12.4066 17.3236 12.3612 16.5756 12.9586C16.3744 13.1193 16.1246 13.331 15.6182 13.8058C13.1327 12.3289 11.6263 10.8042 10.1763 8.36398C10.6569 7.85136 10.8689 7.60083 11.0309 7.39771C11.6272 6.6503 11.583 5.5525 10.8846 4.89943C10.0334 4.10347 9.16719 3.28951 8.26849 2.53635Z"
      stroke="black"
      strokeWidth="1.71429"
      strokeLinejoin="round"
    />
  </svg>
)

export default PhoneTransparent
