import {
  NavigationFeaturedContent,
  Product,
} from '@cerifi/contentful/__generated/graphql.types'
import { BCProduct } from '@cerifi/ecomm/bigcommerce/types/product'

import FeaturedArticle from './featured-article'
import FeaturedImage from './featured-image'
import FeaturedProduct from './featured-product'
import NavMenuHeading from './nav-menu-heading'

const NavMenuFeaturedContent = ({
  link,
  image,
  title,
  titleIcon,
  content,
  textBadge,
}: NavigationFeaturedContent) => {
  function renderContent() {
    if (image?.url) return <FeaturedImage image={image} link={link} />

    if (!content) return null

    switch (content.__typename) {
      case 'Product':
        return <FeaturedProduct {...(content as Product & BCProduct)} />
      case 'ArticlePage':
        return <FeaturedArticle {...content} />
      default:
        return null
    }
  }

  return (
    <div className="flex flex-1 flex-col">
      {title && <NavMenuHeading title={title} icon={titleIcon} />}
      <div className="bg-background relative h-full text-center">
        {textBadge && (
          <div className="absolute inset-x-0 top-0">
            <p className="bg-periwinkle mx-auto w-fit rounded-b px-3 py-1 text-xs font-bold uppercase tracking-[0.125rem] text-white">
              {textBadge}
            </p>
          </div>
        )}
        {renderContent()}
      </div>
    </div>
  )
}

export default NavMenuFeaturedContent
