import { useMemo } from 'react'
import Link from 'next/link'

import { buttonVariants } from '../../components/button'
import { cn } from '../../utils/cn'
import { a11yContrastShade } from '../../utils/color-helpers'
import { getRouteFromContentType } from '../../utils/get-route-from-content-type'

export interface ButtonCmsModel {
  isActive: boolean
  title: string
  color: 'primary' | 'secondary' | 'text'
  type: 'internal link' | 'external link' | 'asset'
  internalLinkId?: string
  externalLink?: string
  assetLink?: string
  sys: {
    id: string
  }
  slug: {
    'en-US': string
  }
}
export const ButtonCms = ({
  content,
  className,
  style,
  backgroundColor,
}: {
  content: ButtonCmsModel
  className?: string
  style?: any
  backgroundColor?: string
}) => {
  const buttonVariant = () => {
    switch (content?.color.toLowerCase()) {
      case 'primary':
        return 'default'
      case 'secondary':
        return 'secondary'
      case 'text':
        return 'link'
      case 'outline':
        const textColor = a11yContrastShade(backgroundColor || '#ffffff')
        if (textColor === '#ffffff') {
          return 'outlineLight'
        }
        return 'outline'
      default:
        return 'default'
    }
  }

  const href = useMemo(() => {
    switch (content.type) {
      case 'internal link':
        return `${getRouteFromContentType(content.sys?.id)}/${
          content.slug ? content.slug['en-US'] : ''
        }`
      case 'external link':
        return content.externalLink as string
      case 'asset':
        return content.assetLink as string
      default:
        console.warn(
          'Unknown content type for contentful button app: ' + content.type
        )
        return null
    }
  }, [content])

  // validation
  if (!content?.isActive) {
    return null
  }
  if (!content?.type || !content?.title) {
    return null
  }
  if (
    content?.type === 'internal link' &&
    (!content?.internalLinkId || !content.sys?.id)
  ) {
    console.warn('button does not have an internal link set', content.title)
    return null
  }
  if (content?.type === 'external link' && !content?.externalLink) {
    console.warn('button does not have an external link set')
    return null
  }
  if (content?.type === 'asset' && !content?.assetLink) {
    console.warn('button does not have an asset attached to it from contentful')
    return null
  }

  return (
    <Link
      className={cn(
        buttonVariants({
          variant: buttonVariant(),
          size: 'default',
          className: '',
        }),
        buttonVariant() === 'link' && 'px-0 lg:px-0',
        className
      )}
      style={style || {}}
      target={content.type !== 'internal link' ? '_blank' : ''}
      href={href!}
      suppressHydrationWarning={href!.includes('tel:') ? true : false}
    >
      {content.title}
    </Link>
  )
}
