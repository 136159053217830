import Link from 'next/link'
import { Product } from '@cerifi/contentful/__generated/graphql.types'
import { BCProduct } from '@cerifi/ecomm/bigcommerce/types/product'

const FeaturedProduct = ({
  sku,
  seoDescription,
  description,
  price,
  name,
  custom_url,
}: Product & BCProduct) => {
  if (!sku) return null

  return (
    <Link href={`/products/${custom_url.url}`}>
      <div className="text-navy-900 mt-3 flex h-full flex-col justify-center p-8">
        {name && <h2 className="mb-5 text-2xl font-sans">{name}</h2>}
        {(seoDescription || description) && (
          <p className="mb-7 line-clamp-3">{seoDescription || description}</p>
        )}
        {price && (
          <p className="text-lg font-semibold">
            {/* <Currency dollars={price.value} /> */}
          </p>
        )}
      </div>
    </Link>
  )
}

export default FeaturedProduct
