'use client'

import { useEffect, useState } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { usePathname, useSearchParams } from 'next/navigation'
import FocusTrap from 'focus-trap-react'
import { StorefrontConfig } from '@cerifi/contentful/__generated/graphql.types'
import useAccountStore from '@cerifi/state/account-store'
import { cn } from '@cerifi/ui/utils/cn'

import { useClickOutside } from '../../../hooks/use-click-outside'
import { Button } from '../../button'
import HideDrift from '../../hide-drift'
import { IconButton } from '../../icon-button'
import { CloseIcon } from '../../icons'
import MobileNavBar from './mobile-nav-bar'
import MobileNavList from './mobile-nav-list'
import styles from './mobile-nav.module.css'

interface MobileNavProps {
  config: StorefrontConfig
  className?: string
}

export function MobileNav({ config, className }: MobileNavProps) {
  const [isOpen, setIsOpen] = useState(false)
  const customer = useAccountStore((state) => state.customer)
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    setIsOpen(false)
  }, [pathname, searchParams])

  const ref = useClickOutside(() => {
    if (isOpen) {
      setIsOpen(false)
    }
  })

  if (!config) return null

  return (
    <div className={className}>
      <MobileNavBar
        storefrontName={config?.storefrontName}
        logo={config?.logo}
        openMobileNav={() => setIsOpen(true)}
        supportNumber={config.supportNumber as string}
      />
      <div
        className={cn(
          isOpen && 'fixed top-0 z-10 h-screen bg-[rgba(0,0,0,0.6)]'
        )}
      >
        <div
          className={cn(
            styles.mobileNav,
            isOpen && styles.open,
            'z-20 mobile-100vh-clamp'
          )}
          ref={ref}
          role="dialog"
          aria-label="Mobile Navigation"
          aria-modal="true"
          aria-hidden={!isOpen}
        >
          {isOpen && (
            <FocusTrap active={isOpen}>
              <div className="flex h-full flex-col">
                <div className="px-3 pt-3 bg-white pb-2 border-b border-darkTan-100">
                  <div className="flex justify-between">
                    {config.logo?.url ? (
                      <div className="relative h-[3rem] w-full">
                        <Image
                          src={config.logo.url}
                          alt={
                            config.logo.description ||
                            config.storefrontName ||
                            'Logo'
                          }
                          style={{
                            objectFit: 'contain',
                            objectPosition: 'left',
                          }}
                          fill
                        />
                      </div>
                    ) : (
                      <h1>{config.storefrontName}</h1>
                    )}

                    <IconButton
                      onClick={() => setIsOpen(false)}
                      variant="ghost"
                      aria-label="Close Mobile Nav Menu"
                    >
                      <CloseIcon></CloseIcon>
                    </IconButton>
                  </div>
                </div>

                <MobileNavList
                  items={config.navigationCollection?.items}
                  onSearch={() => setIsOpen(false)}
                />

                <HideDrift />
                <div className="bg-background flex gap-3 p-3">
                  {customer ? (
                    <Link
                      href="/account"
                      className="flex-1 py-3 text-center font-semibold"
                      onClick={() => setIsOpen(false)}
                      data-testid="mobile-logged-in-account"
                    >
                      Account
                    </Link>
                  ) : (
                    <Link
                      href="/login"
                      className="flex-1"
                      data-testid="mobile-guest-account"
                    >
                      <Button
                        variant="link"
                        className="w-full px-2 font-semibold"
                        tabIndex={-1}
                        role="presentation"
                        onClick={() => setIsOpen(false)}
                      >
                        Account
                      </Button>
                    </Link>
                  )}
                  <Link
                    href="/student-logins"
                    className="flex-1"
                    onClick={() => setIsOpen(false)}
                  >
                    <Button
                      className="w-full px-2"
                      tabIndex={-1}
                      role="presentation"
                    >
                      Student Sign In
                    </Button>
                  </Link>
                </div>
              </div>
            </FocusTrap>
          )}
        </div>
      </div>
    </div>
  )
}
