import Link from 'next/link'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { ArticlePage } from '@cerifi/contentful/__generated/graphql.types'

const FeaturedArticle = ({
  slug,
  articleTitle,
  author,
  minutesToRead,
  description,
}: ArticlePage) => {
  if (!slug) return null

  return (
    <Link href={`/article/${slug}`}>
      <div className="text-navy-900 p-8">
        {articleTitle && (
          <h2 className="mb-6 text-lg xl:text-xl font-sans">
            {documentToReactComponents(articleTitle?.json)}
          </h2>
        )}
        <div className="mb-6 flex items-center justify-center gap-4 text-xs font-semibold">
          {minutesToRead && <p>{minutesToRead} MIN READ</p>}
          {minutesToRead && author?.name && (
            <div className="bg-navy-800 h-4 w-px" />
          )}
          {author?.name && <p>{author.name}</p>}
        </div>
        {description && (
          <div className="line-clamp-3">
            {documentToReactComponents(description.json)}
          </div>
        )}
      </div>
    </Link>
  )
}

export default FeaturedArticle
