/** Audit time is similar to debouncing. Instead of waiting until the events end (like a
 * user typing) audit time will fire events as the user types (or other events) but space them out. It
 * is the same as a throttle except that audit time will ensure that the last request is fired. This makes
 * it great for SEARCH BARS, TYPE-AHEADS, etc...
 */

//let timeout: any
export class AuditTime {
  private callback!: Function
  private time!: number
  private timeout!: any
  private currentValue!: any

  /**
   * @param callback function to run after delay
   * @param time time in ms between events
   */
  constructor(callback: Function, time = 300) {
    this.callback = callback
    this.time = time
  }

  private complete() {
    this.callback(this.currentValue)
    this.timeout = null
  }

  /** call this every time you want an event and it will call the callback after throttling to the delayed this.time */
  fire(value: any) {
    this.currentValue = value

    if (!this.timeout) {
      this.timeout = setTimeout(() => this.complete(), this.time)
    }
  }
}

export class DebounceTime {
  private callback!: Function
  private time!: number
  private timeout!: any
  private currentValue!: any

  /**
   * @param callback function to run after delay
   * @param time time in ms between events
   */
  constructor(callback: Function, time = 300) {
    this.callback = callback
    this.time = time
  }

  private complete() {
    this.callback(this.currentValue)
    this.timeout = null
  }

  /** call this every time you want an event and it will call the callback after throttling to the delayed this.time */
  fire(value: any) {
    this.currentValue = value
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => this.complete(), this.time)
  }
}
