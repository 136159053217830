'use client'

import Link from 'next/link'
import useAccountStore from '@cerifi/state/account-store'
import { useUiEventStore } from '@cerifi/state/ui-event.store'

import { Button } from '../../../button'
import PhoneSolid from '../../../icons/phone-solid'

interface Props {
  supportNumber: string
}

const SecondaryNav = ({ supportNumber }: Props) => {
  const secondaryNavStyles = 'text-sm hover:text-primary p-1'
  const customer = useAccountStore((state) => state.customer)
  const setIsQuickSearchOpen = useUiEventStore(
    (state) => state.setIsQuickSearchOpen
  )

  return (
    <div className="flex items-center justify-end gap-3 md:gap-6">
      <button
        id="search-trigger-desktop"
        className={secondaryNavStyles}
        onClick={() => setIsQuickSearchOpen(true)}
      >
        Search
      </button>

      <Link href="/student-logins" className={secondaryNavStyles}>
        Student Sign In
      </Link>

      {customer ? (
        <Link href="/account" className={secondaryNavStyles}>
          Account
        </Link>
      ) : (
        <>
          <Link href="/login" className={secondaryNavStyles}>
            Account
          </Link>
        </>
      )}

      {supportNumber && (
        <Button size="sm">
          <Link
            href={`tel:${supportNumber}`}
            aria-label="button to call support"
          >
            <div className="flex items-center text-xs">
              <PhoneSolid className="pt-1" />
              <div className="pl-2">{supportNumber}</div>
            </div>
          </Link>
        </Button>
      )}
    </div>
  )
}

export default SecondaryNav
