import Image from 'next/image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Link as NavigationMenuLink } from '@radix-ui/react-navigation-menu'
import { NavigationCategoryItem } from '@cerifi/contentful/__generated/graphql.types'
import Link from '@cerifi/ui/components/link'

import contentfulImage from '../../../../utils/contentful-image'

const NavMenuCategoryItem = ({
  link,
  title,
  description,
  icon,
}: NavigationCategoryItem) => {
  const className = 'bg-white px-5 py-4 xl:px-6 xl:py-5 rounded-sm'

  const content = (
    <div
      className="flex h-full items-center gap-5"
      data-testid="nav-category-card"
    >
      <div className="min-w-[2.5rem]">
        {icon?.url && (
          <Image
            alt=""
            src={contentfulImage(icon.url)}
            width="40"
            height="40"
          />
        )}
      </div>
      <div>
        <h3
          data-testid="nav-category-card-title"
          className="mb-1 font-sans text-sm font-bold"
        >
          {title}
        </h3>
        <div className="text-xs" data-testid="nav-category-card-description">
          {documentToReactComponents(description?.json)}
        </div>
      </div>
    </div>
  )

  if (link) {
    return (
      <Link
        component={NavigationMenuLink}
        {...link}
        className={className}
        data-testid="nav-item"
      >
        {content}
      </Link>
    )
  }

  return <div className={className}>{content}</div>
}

export default NavMenuCategoryItem
