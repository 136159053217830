import Image from 'next/image'
import Link from 'next/link'
import {
  Maybe,
  PageAffiliate,
} from '@cerifi/contentful/__generated/graphql.types'
import { ButtonCms, ButtonCmsModel } from '@cerifi/ui/cms/button/button.cms'

import contentfulImage from '../../../../utils/contentful-image'

const Affiliates = ({
  affiliates,
  button,
}: {
  affiliates: Maybe<PageAffiliate>[]
  button: ButtonCmsModel
}) => {
  return (
    <div className="flex w-full flex-col items-center">
      <div className="mb-8 grid grid-cols-5 gap-x-[1.125rem] gap-y-[.8125rem] xl:grid-cols-6">
        {affiliates.map(
          (x) =>
            x?.slug && (
              // using the a tag instead of Next/link since next link component uses only client side navigation, which does not trigger the university nav in the layout to be updated until the next refresh
              <a
                key={x.sys?.id}
                href={`/university/${x.slug}`}
                className="bg-white p-3 relative"
                data-testid="university-nav-link"
              >
                {(x.standardScaleLogo?.url || x.logo?.url) && (
                  <Image
                    src={contentfulImage(
                      x.standardScaleLogo?.url || (x.logo?.url as string)
                    )}
                    alt={
                      x.standardScaleLogo?.description ||
                      x.logo?.description ||
                      x.title ||
                      'University logo'
                    }
                    width="190"
                    height="97"
                    className="h-full max-w-full object-contain object-center"
                  />
                )}
                <div className="will-change-transform hover:opacity-90 transition duration-75 ease-out absolute inset-0 p-2 text-center bg-navy-800 text-white font-medium flex items-center justify-center opacity-0">
                  {x.title}
                </div>
              </a>
            )
        )}
      </div>
      {button && <ButtonCms content={button} />}
    </div>
  )
}

export default Affiliates
