import { useState } from 'react'
import NextLink from 'next/link'
import {
  Maybe,
  NavigationItem,
} from '@cerifi/contentful/__generated/graphql.types'
import { useUiEventStore } from '@cerifi/state/ui-event.store'
import Link from '@cerifi/ui/components/link'
import { getRouteFromContentType } from '@cerifi/ui/utils/get-route-from-content-type'

import { cn } from '../../../utils/cn'
import ArrowLeftIcon from '../../icons/arrow-left'
import Search from '../../icons/search'
import MobileNavItem from './mobile-nav-item'

interface Props {
  items: Maybe<NavigationItem>[] | undefined
  onSearch: () => void
}

const MobileNavList = ({ items, onSearch }: Props) => {
  const [selected, setSelected] = useState<NavigationItem | undefined>(
    undefined
  )
  const openQuickSearch = useUiEventStore((state) => state.setIsQuickSearchOpen)
  const className =
    'py-5 font-semibold block w-full border-b border-darkTan-100 text-lg'

  if (!items?.length) return null

  return (
    <div className="flex-1 overflow-y-auto pb-4 bg-backgroundTint">
      <nav aria-label="mobile navigation">
        {selected ? (
          <div>
            <button className="bg-background w-full border-b border-neutral-300 px-4 py-5">
              <div
                className="mb-4 flex items-center gap-3 text-sm"
                onClick={() => setSelected(undefined)}
              >
                <ArrowLeftIcon /> Back
              </div>
              <h2 className="text-left text-lg font-semibold body-font">
                {selected.title}
              </h2>
            </button>
            <ul className="divide-y divide-neutral-300 text-lg">
              {selected.navigationCategoriesCollection?.items
                ?.flatMap((x) => x?.itemsCollection)
                ?.flatMap((x) => x?.items)
                ?.map(
                  (x) =>
                    x?.link && (
                      <li key={x.sys?.id} className="hover:bg-tan-100 px-4">
                        <Link
                          {...x.link}
                          className="block py-[1.13rem] font-normal"
                          data-testid="mobile-nav-link"
                        >
                          {x.title}
                        </Link>
                      </li>
                    )
                )}
              {!!selected.affiliatesCollection?.items?.length && (
                <>
                  {selected?.button && (
                    <li className="block py-5 font-normal hover:bg-tan-100 px-4">
                      <NextLink
                        data-testid="mobile-nav-link"
                        href={
                          (selected.button.type === 'internal link'
                            ? `${getRouteFromContentType(
                                selected.button.sys?.id
                              )}/${
                                selected.button.slug
                                  ? selected.button.slug['en-US']
                                  : ''
                              }`
                            : selected.button.externalLink) as string
                        }
                      >
                        {selected.button.title}
                      </NextLink>
                    </li>
                  )}
                  {selected.affiliatesCollection.items.map(
                    (x) =>
                      x && (
                        <li key={x.sys?.id} className="hover:bg-tan-100 px-4">
                          <NextLink
                            href={`/university/${x.slug}`}
                            data-testid="mobile-nav-link"
                            className="block py-5 font-normal"
                          >
                            {x.title}
                          </NextLink>
                        </li>
                      )
                  )}
                </>
              )}
            </ul>
          </div>
        ) : (
          <ul>
            {items.map(
              (item) =>
                item && (
                  <MobileNavItem
                    key={item.sys?.id}
                    {...item}
                    setSelected={() => setSelected(item)}
                  />
                )
            )}
            <li className="hover:bg-tan-100 px-3" key={'search'}>
              <button
                onClick={() => {
                  onSearch()
                  openQuickSearch(true)
                }}
                className={cn(className, 'flex w-full items-center ')}
                data-testid="mobile-nav-category"
              >
                <Search />
                <span className="pl-1">Search</span>
              </button>
            </li>
          </ul>
        )}
      </nav>
    </div>
  )
}

export default MobileNavList
