import Image from 'next/image'
import {
  Asset,
  Link as LinkType,
  Maybe,
} from '@cerifi/contentful/__generated/graphql.types'
import Link from '@cerifi/ui/components/link'

import contentfulImage from '../../../../utils/contentful-image'

interface Props {
  image: Asset
  link?: Maybe<LinkType>
}

const FeaturedImage = ({ image, link }: Props) => {
  if (!image?.url) return null

  const img = (
    <Image
      style={{ objectFit: 'cover' }}
      alt={image.description || 'Featured Content'}
      src={contentfulImage(image.url)}
      fill
      className="bg-[#f3f6f8]"
    />
  )

  return (
    <div className="relative flex-1">
      {link ? (
        <Link {...link} className="block h-full">
          {img}
        </Link>
      ) : (
        <>{img}</>
      )}
    </div>
  )
}

export default FeaturedImage
