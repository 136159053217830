'use client'

import { useEffect, useRef, useState } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import {
  Maybe,
  NavigationItem,
} from '@cerifi/contentful/__generated/graphql.types'
import { cn } from '@cerifi/ui/utils/cn'

import NavMenuItem from './nav-menu-item'

interface NavigationProps {
  items: Maybe<NavigationItem>[] | undefined
  className: string
}

const NavMenu = ({ items, className }: NavigationProps) => {
  const [value, setValue] = useState<string | undefined>(undefined)
  const [disableClick, setDisableClick] = useState(false)
  const targetRef = useRef<HTMLButtonElement[]>([])
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    setValue(undefined)
  }, [pathname, searchParams])

  // Some users click the menu triggers to open them,
  // which will close the menu as their "hover" state has already triggered the menu to open.
  // Here we're adding a mutation observer to listen to the data-state attributes of the trigger element
  // and prevent any onClick events within a given interval
  // https://github.com/radix-ui/primitives/issues/1630#issuecomment-1545995075
  useEffect(() => {
    // Callback function
    const observerCallback = (mutationsList: any) => {
      for (const mutation of mutationsList) {
        if (
          mutation.type === 'attributes' &&
          mutation.attributeName === 'data-state' &&
          mutation.target.dataset.state === 'open'
        ) {
          setDisableClick(true)
          const timeout = setTimeout(() => {
            setDisableClick(false)
            clearTimeout(timeout)
          }, 1000)
        }
      }
    }

    // Init MutationObserver
    const observer = new MutationObserver(observerCallback)

    // Add ref nodes to observer watch
    targetRef.current.forEach((element) => {
      if (element) {
        observer.observe(element, {
          attributes: true,
        })
      }
    })

    // Disconnect on dismount
    return () => {
      observer.disconnect()
    }
  }, [])

  if (!items?.length) return null

  return (
    <NavigationMenu.Root
      value={value}
      onValueChange={setValue}
      className={cn('z-[1] w-full', className)}
    >
      <NavigationMenu.List className="center m-0 flex list-none flex-wrap p-1">
        {items.map(
          (item, i) =>
            item && (
              <NavMenuItem
                key={item.sys?.id}
                navValue={value}
                {...item}
                clickDisabled={disableClick}
                triggerRefCallback={(ref: HTMLButtonElement) =>
                  (targetRef.current[i] = ref)
                }
              />
            )
        )}

        <NavigationMenu.Indicator
          style={{ top: 'calc(100% - 10px)' }}
          className="data-[state=hidden]:animate-fadeOut data-[state=visible]:animate-fadeIn z-[1] flex h-2.5 items-end justify-center overflow-hidden"
        >
          <div className="width-25 h-1.25 w-25 bg-white" />
        </NavigationMenu.Indicator>
      </NavigationMenu.List>

      <div className="absolute inset-x-0 top-full">
        <NavigationMenu.Viewport className="transition-[height, width] data-[state=closed]:animate-scaleOut data-[state=open]:animate-scaleIn h-[var(--radix-navigation-menu-viewport-height)] origin-[top_center] overflow-hidden bg-white drop-shadow-lg duration-300" />
      </div>
    </NavigationMenu.Root>
  )
}

export default NavMenu
