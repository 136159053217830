import { LucideProps } from 'lucide-react'

const Cart = (props: LucideProps) => (
  <svg
    {...props}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.27113 0.857178H5.55684L7.04827 15.6857C7.11026 16.0945 7.3179 16.4671 7.63292 16.7349C7.94795 17.0027 8.34913 17.1476 8.76255 17.1429H19.5626C19.9364 17.1624 20.3063 17.059 20.6159 16.8485C20.9254 16.638 21.1576 16.332 21.2768 15.9772L23.5568 9.12004C23.6418 8.86223 23.6644 8.58793 23.6227 8.3197C23.581 8.05146 23.4761 7.79698 23.3168 7.57718C23.1509 7.34348 22.929 7.15513 22.6714 7.02935C22.4139 6.90357 22.1289 6.84437 21.8426 6.85718H6.15684"
      stroke="#090936"
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2713 23.1429C18.7979 23.1429 18.4141 22.7591 18.4141 22.2857C18.4141 21.8123 18.7979 21.4286 19.2713 21.4286C19.7447 21.4286 20.1284 21.8123 20.1284 22.2857C20.1284 22.7591 19.7447 23.1429 19.2713 23.1429Z"
      stroke="#090936"
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.12821 23.1429C7.65482 23.1429 7.27107 22.7591 7.27107 22.2857C7.27107 21.8123 7.65482 21.4286 8.12821 21.4286C8.6016 21.4286 8.98535 21.8123 8.98535 22.2857C8.98535 22.7591 8.6016 23.1429 8.12821 23.1429Z"
      stroke="#090936"
      strokeWidth="1.71429"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default Cart
