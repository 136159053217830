'use client'

import { useEffect, useState } from 'react'
import {
  PageAffiliate,
  PageAffiliateAffiliateNavigationItem,
} from '@cerifi/contentful/__generated/graphql.types'
import { useUniversityStore } from '@cerifi/state/university.store'
import { Container } from '@cerifi/ui/components/container'
import contentfulImage from '@cerifi/ui/utils/contentful-image'
import { fetcher } from '@cerifi/utils/fetcher'

const renderNavItem = (x: PageAffiliateAffiliateNavigationItem) => {
  let label = ''
  let link = ''
  let target = ''

  switch (x.__typename) {
    case 'Link':
      if (!x.title || !x.url) return null

      label = String(x.title)
      link = String(x.url)
      target = String(x.target) == 'New Tab' ? '_blank' : ''

      break
    case 'Page':
    case 'PageAffiliate':
    case 'PagePackageCollection':
      if (!x.title || !x.slug) return null

      label = String(x.title)
      link = String(x.slug)
    default:
      return null
  }

  return (
    <a
      href={link}
      data-testid="university-link"
      className="block rounded py-2 pl-3 pr-4 font-medium text-gray-900 hover:bg-gray-100 md:border-0 md:p-0 md:hover:bg-transparent md:hover:text-blue-700"
      target={target}
    >
      {label}
    </a>
  )
}

const UniversityNavUI = ({
  university: initialUniversity,
}: {
  university: PageAffiliate
}) => {
  const [university, setUniversity] = useState(initialUniversity)
  const [universityEntryId] = useUniversityStore((state) => [
    state.universityEntryId,
  ])

  useEffect(() => {
    if (!universityEntryId || universityEntryId === university.sys?.id) return

    fetcher(`/api/university?universityEntryId=${universityEntryId}`, {
      method: 'GET',
    }).then((data) => {
      setUniversity(data)
    })
  }, [university.sys?.id, universityEntryId])

  return (
    <nav className="border-tan-350 z-40 hidden border-b bg-neutral-50 md:block">
      <Container className=" flex flex-wrap items-center justify-between py-4">
        <div className="flex items-center gap-4">
          {university.logo?.url && (
            <a
              href={university.slug as string}
              aria-label={
                'Link to' + university.logo?.description + 'home page'
              }
            >
              <img
                src={contentfulImage(university.logo.url)}
                className="mr-3 h-12"
                alt={university.logo?.description || 'University Logo'}
              />
            </a>
          )}
          <p className="text-xs leading-[0.9rem] w-[150px]">
            {university.affiliateNavigationBarText ?? (
              <span>
                In collaboration with
                <br />
                Dalton Education
              </span>
            )}
          </p>
        </div>
        {!!university.affiliateNavigationCollection?.items?.length && (
          <div className="w-full md:block md:w-auto" id="navbar-default">
            <ul className="mt-4 flex flex-col rounded-lg border-0 md:mr-3 md:mt-0 md:flex-row md:space-x-8">
              {university.affiliateNavigationCollection.items.map(
                (x: any) =>
                  x && (
                    <li key={x.sys?.id}>
                      {renderNavItem(x as PageAffiliateAffiliateNavigationItem)}
                    </li>
                  )
              )}
            </ul>
          </div>
        )}
      </Container>
    </nav>
  )
}

export default UniversityNavUI
