import fontColorContrast from 'better-font-color-contrast'
import ColorContrastChecker from 'color-contrast-checker'

/** converts a string hex code for a color into a rgb format */
export function hexToRgb(hex: string, opacity: number) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result && result.length > 2
    ? `rgba(${parseInt(result[1] as string, 16)}, ${parseInt(
        result[2] as string,
        16
      )}, ${parseInt(result[3] as string, 16)}, ${opacity})`
    : null
}

/** returns a css rgba() function */
export function hexToRgbaCSS(hex: string, opacity: number) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result && result.length > 2
    ? `rgba(${parseInt(result[1] as string, 16)}, ${parseInt(
        result[2] as string,
        16
      )}, ${parseInt(result[3] as string, 16)}, ${opacity})`
    : null
}

export const colorChecker = new ColorContrastChecker()
export function darkenColorUntilAllowed(
  color: string,
  background?: string
): string {
  if (colorChecker.isLevelAA(color, background || '#FFFFFF', 12)) {
    return color
  }

  return darkenColorUntilAllowed(darkenColor(color, 10))
}

/** Cite: https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors, slightly edited */
export function darkenColor(color: string, percent: number) {
  var R = parseInt(color.substring(1, 3), 16)
  var G = parseInt(color.substring(3, 5), 16)
  var B = parseInt(color.substring(5, 7), 16)

  R = parseInt(`${(R * (100 - percent)) / 100}`)
  G = parseInt(`${(G * (100 - percent)) / 100}`)
  B = parseInt(`${(B * (100 - percent)) / 100}`)

  R = R < 255 ? R : 255
  G = G < 255 ? G : 255
  B = B < 255 ? B : 255

  R = Math.round(R)
  G = Math.round(G)
  B = Math.round(B)

  var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16)
  var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16)
  var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16)

  return '#' + RR + GG + BB
}

/** Pass in a background color and receive an accessible text color
 * @param color the hex background color
 * @returns  '#ffffff'|'#000000' ffffff for recommended white text and 000000 for black text
 */
export function a11yContrastShade(color: string) {
  return fontColorContrast(color)
}
