'use client'

import React, { useEffect, useId, useRef, useState } from 'react'
import { useRouter } from 'next/navigation'
import * as Dialog from '@radix-ui/react-dialog'
import { SearchClient } from 'algoliasearch'
import algoliasearch from 'algoliasearch/lite'
import { Configure, InstantSearch } from 'react-instantsearch-hooks-web'
import { useUiEventStore } from '@cerifi/state/ui-event.store'
import { cn } from '@cerifi/ui/utils/cn'

import { SEARCH_INDEX_NAME } from '../search-model'
import { SearchAutocomplete } from './search-auto-complete'
import styles from './search-menu.module.css'

const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID as string,
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY as string
)

const searchClient: SearchClient = {
  ...algoliaClient,
  search(requests: any) {
    if (requests.every(({ params }: any) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          hitsPerPage: 0,
          exhaustiveNbHits: false,
          query: '',
          params: '',
        })),
      })
    }
    return algoliaClient.search(requests)
  },
} as SearchClient

export const SearchMenu = () => {
  const [isOpen, setIsOpen] = useUiEventStore((state) => [
    state.isQuickSearchOpen,
    state.setIsQuickSearchOpen,
  ])

  const router = useRouter()

  const [term, setTerm] = useState('')
  const ref = useRef<HTMLDivElement>(null)

  /** Distance to top of screen, used to place the search bar */
  const [top, setTop] = useState(0)

  useEffect(() => {
    const onResize = () => {
      if (ref.current) {
        setTop(ref.current.getBoundingClientRect().top)
      }
    }

    onResize()
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  const onOpenChange = () => {
    setIsOpen(!isOpen)

    if (isOpen) {
      setTimeout(() => {
        if (screen.width > 1024) {
          document.getElementById('search-trigger-desktop')?.focus()
        } else {
          document.getElementById('search-trigger-mobile')?.focus()
        }
      })
    }
  }

  return (
    <div ref={ref}>
      <Dialog.Root open={isOpen} onOpenChange={() => onOpenChange()}>
        <Dialog.Portal>
          <Dialog.Overlay className={cn(styles.overlay)} />
          <Dialog.Content
            className={cn('animate-slideIn absolute w-[100vw] bg-white')}
            style={{ top: `${top}px`, zIndex: 100 }}
          >
            <Dialog.Title className="sr-only">Search</Dialog.Title>

            <div className="my-6">
              <InstantSearch
                searchClient={searchClient}
                indexName={SEARCH_INDEX_NAME}
              >
                <Configure hitsPerPage={3} />
                <SearchAutocomplete
                  valueChange={(v) => setTerm(v)}
                  close={() => {
                    setIsOpen(false)
                    router.push(`/search?term=${term}`)
                  }}
                />
              </InstantSearch>
            </div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </div>
  )
}
