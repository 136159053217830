import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/apps/dalton-education/src/components/search/search-menu/search-menu.tsx");
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/apps/dalton-education/src/components/university/university-nav-ui.tsx");
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js");
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/packages/contentful/components/content-block/content-block.tsx");
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/packages/ui/components/draft-mode-alert/draft-mode-banner.tsx");
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/packages/ui/components/site-header/announcement-banner/announcement-banner.module.css");
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/packages/ui/components/site-header/cart-icon/index.tsx");
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/packages/ui/components/site-header/desktop-nav/nav-menu/index.tsx");
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/packages/ui/components/site-header/desktop-nav/secondary-nav/index.tsx");
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/packages/ui/components/site-header/mobile-nav/index.tsx");
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/packages/ui/components/site-header/skip-link/skip-link.module.css");
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/packages/ui/components/smart-image/smart-image.tsx")