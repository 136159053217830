'use client'

import { useEffect, useState } from 'react'
import Link from 'next/link'
import useAccountStore from '@cerifi/state/account-store'

import Cart from '../../icons/cart'

interface CartIconProps {
  className?: string
}

export const CartIcon = ({ className }: CartIconProps) => {
  const [cart] = useAccountStore((state) => [state.cart])
  const [totalItems, setTotalItems] = useState(0)

  useEffect(() => {
    if (!cart || !cart.lineItems) {
      setTotalItems(0)
      return
    }
    const total = cart.lineItems.reduce(
      (partialSum, a) => partialSum + a.quantity,
      0
    )

    setTotalItems(total)
  }, [cart])

  return (
    <Link
      href="/cart"
      title="Cart"
      className={`relative -mr-1 ml-1 ${className}`}
    >
      <Cart />
      {totalItems > 0 && (
        <div
          className="bg-primary absolute right-0 top-[-5px] h-[1.25rem] px-1 leading-[1.125rem] rounded-sm text-center text-xs text-white"
          data-testid="cart-item-count"
        >
          {totalItems}
        </div>
      )}
    </Link>
  )
}
