import { CSSProperties } from 'react'
import Image from 'next/image'
import { Asset, Maybe } from '@cerifi/contentful/__generated/graphql.types'
import { Divider } from '@cerifi/ui/components/divider'

import contentfulImage from '../../../../utils/contentful-image'

interface Props {
  title: string
  icon?: Maybe<Asset>
  style?: CSSProperties
}

const NavMenuHeading = ({ title, icon, style }: Props) => {
  return (
    <div style={style}>
      <div className="flex min-h-[1.625rem] items-center gap-3">
        {icon?.url && (
          <Image
            src={contentfulImage(icon.url)}
            alt=""
            width="26"
            height="26"
          />
        )}
        <h2 className="font-sans uppercase leading-5 tracking-[0.125rem]">
          {title}
        </h2>
      </div>
      <Divider className="mb-5 mt-2" />
    </div>
  )
}

export default NavMenuHeading
