import { create } from 'zustand'

interface UiEventStore {
  isQuickSearchOpen: boolean
  setIsQuickSearchOpen: (isOpen: boolean) => void
  /** Should be set to true whenever a dialog or anything else causes an overlay to
   * block the rest of the screen.
   */
  isOverlayOpen: boolean
  setIsOverlayOpen: (isOpen: boolean) => void
}

export const useUiEventStore = create<UiEventStore>((set) => ({
  isQuickSearchOpen: false,
  setIsQuickSearchOpen: (isOpen) =>
    set({
      isQuickSearchOpen: isOpen,
    }),
  isOverlayOpen: false,
  setIsOverlayOpen: (isOpen) =>
    set({
      isOverlayOpen: isOpen,
    }),
}))
