import { NavigationItem } from '@cerifi/contentful/__generated/graphql.types'
import Link from '@cerifi/ui/components/link'
import { cn } from '@cerifi/ui/utils/cn'

import { ChevronRightIcon } from '../../icons'

const MobileNavItem = ({
  title,
  link,
  navigationCategoriesCollection,
  affiliatesCollection,
  setSelected,
}: NavigationItem & { setSelected: () => void }) => {
  const className =
    'py-5 font-semibold block w-full border-b border-darkTan-100 text-lg'

  if (
    navigationCategoriesCollection?.items?.length ||
    affiliatesCollection?.items?.length
  ) {
    return (
      <li className="hover:bg-tan-100 px-3">
        <button
          onClick={() => setSelected()}
          className={cn(className, 'flex w-full items-center justify-between ')}
          data-testid="mobile-nav-category"
        >
          {title}
          <span className="sr-only">Navigation Sub-menu</span>
          <ChevronRightIcon
            width={25}
            height={25}
            className={cn('relative top-px mr-2 text-darkTan-100')}
            aria-hidden
          />
        </button>
      </li>
    )
  }

  if (link?.url && title) {
    return (
      <li className="px-3 hover:bg-tan-100">
        <Link {...link} className={className}>
          {title}
        </Link>
      </li>
    )
  }

  return <li className={className}>{title}</li>
}

export default MobileNavItem
