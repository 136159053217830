import { ComponentType, ReactNode } from 'react'
import NextLink from 'next/link'
import { Link as LinkProps } from '@cerifi/contentful/__generated/graphql.types'

interface props extends LinkProps {
  component?: ComponentType<any>
  children?: ReactNode
  className?: string
}

const Link = ({
  url,
  target,
  external,
  noIndex,
  noFollow,
  ariaLabel,
  component,
  children,
  className,
}: props) => {
  if (!url) return null

  const Component = component || NextLink

  return (
    <Component
      href={url}
      target={target == 'New Tab' ? '_blank' : undefined}
      rel={`${external ? 'noreferrer' : ''} ${noIndex ? 'noindex' : ''} ${
        noFollow ? 'nofollow' : ''
      }`}
      aria-label={ariaLabel || undefined}
      className={className}
    >
      {children}
    </Component>
  )
}

export default Link
