'use client'

import { useEffect, useId, useRef, useState } from 'react'
import connectAutocomplete, {
  type AutocompleteConnectorParams,
  type AutocompleteWidgetDescription,
} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete'
import { useConnector } from 'react-instantsearch-hooks-web'
import { Container } from '@cerifi/ui/components/container'
import { CloseIcon } from '@cerifi/ui/components/icons'
import Search from '@cerifi/ui/components/icons/search'
import { DebounceTime } from '@cerifi/ui/utils/audit-time'
import { cn } from '@cerifi/ui/utils/cn'
import { ariaSpeak } from '@cerifi/utils/aria-speak'

import { Hit } from '../search-page/search-hit'

interface UseAutocompleteProps extends AutocompleteConnectorParams {
  close: () => void
  valueChange: (v: string) => void
}

function useAutocomplete(props?: AutocompleteConnectorParams) {
  return useConnector<
    AutocompleteConnectorParams,
    AutocompleteWidgetDescription
  >(connectAutocomplete, props)
}

export function SearchAutocomplete({ close, ...props }: UseAutocompleteProps) {
  const { indices, refine } = useAutocomplete(props)

  const container = useRef<HTMLDivElement>()
  const [auditTime] = useState(
    new DebounceTime((value: string) => refine(value))
  )

  const [value, _setValue] = useState('')
  const setValue = (v: string) => {
    _setValue(v)
    props.valueChange(v)
  }
  const ref = useRef<HTMLInputElement>(null)
  const id = useId()

  useEffect(() => {
    if (ref.current) {
      ref.current.focus()
    }
  }, [])

  useEffect(() => {
    if (indices && indices[0]?.hits && indices[0].hits.length > 0) {
      ariaSpeak(
        `${indices[0].hits.length} quick results loaded. Hit tab to focus or enter to full search term`,
        'assertive'
      )
    }
  }, [indices])

  useEffect(() => {
    const listen = (event: KeyboardEvent) => {
      if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
        if (container.current) {
          const btns = Array.from(container.current.querySelectorAll('a'))
          if (btns.includes(document.activeElement as any)) {
            const index = btns.findIndex((el) => el === document.activeElement)
            let newIndex = index + (event.key === 'ArrowDown' ? 1 : -1)
            if (newIndex < 0) {
              newIndex = btns.length - 1
            } else if (newIndex >= btns.length) {
              newIndex = 0
            }
            btns[newIndex]?.focus()
          } else if (event.key === 'ArrowDown') {
            btns[0]?.focus()
          }
        }
      }
    }

    document.addEventListener('keydown', listen)

    return () => document.removeEventListener('keydown', listen)
  }, [])

  return (
    <Container className="mt-12">
      <div className={cn('w-full ')}>
        <div className="border-backgroundShade-400 relative flex items-center border-b">
          <input
            value={value}
            ref={ref}
            onChange={(v) => {
              setValue(v.target.value)
              auditTime.fire(v.target.value)
            }}
            placeholder="Type to search products, pages, or articles"
            aria-label="Search"
            list={`${id}-list`}
            id={`${id}-input`}
            className={'bg-backgroundShade-50 w-full py-2 pl-3 pr-6 text-2xl'}
            autoComplete="off"
            onKeyDown={(k) => {
              if (k.key === 'Enter') {
                close()
              }
            }}
          />
          {!value && (
            <div className="absolute inset-y-0 right-3 flex items-center">
              <Search />
            </div>
          )}

          {value && (
            <button
              aria-label="clear"
              className="absolute inset-y-0 right-3 flex items-center"
              onClick={() => {
                setValue('')
                refine('')
              }}
            >
              <CloseIcon />
            </button>
          )}
        </div>
      </div>

      <div className="mt-6" ref={container as any}>
        {indices.length > 0 &&
          indices[0]?.hits.map((h) => (
            <div key={h.title}>
              <Hit hit={h as any} />
            </div>
          ))}
      </div>
    </Container>
  )
}
