import { useMemo } from 'react'
import Link from 'next/link'
import { useUiEventStore } from '@cerifi/state/ui-event.store'

import { SearchHit } from '../search-model'

export function Hit({ hit }: { hit: SearchHit }) {
  const setSearchMenuOpen = useUiEventStore(
    (state) => state.setIsQuickSearchOpen
  )

  const link = useMemo(() => {
    switch (hit.type) {
      case 'Page':
        return `/${hit.slug}`
      case 'Product':
        return `/products/${hit.slug}`
      case 'Article':
        return `/article/${hit.slug}`
      default:
        console.log('unknown search type received', hit)
        return '/'
    }
  }, [hit])

  return (
    <Link
      className="mb-6 block border border-neutral-300 bg-white p-6 transition-colors hover:bg-neutral-100 focus:bg-neutral-100"
      href={link}
      onClick={() => setSearchMenuOpen(false)}
    >
      <div className="flex flex-wrap items-center justify-between">
        <div>
          <div className="mb-3 ">
            <div className="text-xs font-medium uppercase text-neutral-700">
              {hit.type}
            </div>
          </div>
          <h2 className="body-font font-medium">{hit.title}</h2>
        </div>
      </div>
    </Link>
  )
}
