export const getRouteFromContentType = (type: string) => {
  switch (type) {
    case 'page':
      return ''
    case 'articlePage':
      return '/articles'
    case 'pageArticleIndex':
      return '/article'
    case 'pagePackageCollection':
      return '/package'
    case 'pageAffiliate':
      return '/university'
    case 'product':
      return '/products'
    case undefined:
      return ''
    default:
      console.warn('Content type not found', type)
      return ''
  }
}
