import { LucideProps } from 'lucide-react'

const PhoneSolid = (props: LucideProps) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.82367 1.48389C6.59876 0.472724 4.77306 0.610725 3.84296 1.89828C3.54094 2.31636 3.21471 2.79375 2.56789 3.60284C0.677775 5.96719 0.677782 9.39068 2.57835 11.7466C3.98137 13.4859 5.45242 15.2408 7.09688 16.8852C8.74135 18.5297 10.4963 20.0008 12.2355 21.4037C14.5915 23.3044 18.015 23.3044 20.3794 21.4142C21.2321 20.7324 21.7119 20.4115 22.1437 20.098C23.3849 19.1969 23.5542 17.4405 22.5906 16.2471C21.6839 15.124 20.6538 14.0589 19.6693 13.0205C19.0107 12.3259 17.9135 12.2836 17.1846 12.904C16.7295 13.2913 16.3714 13.5831 15.5544 14.349C12.8499 12.742 11.2109 11.0829 9.6331 8.42772C10.4032 7.60625 10.6955 7.24719 11.0863 6.78826C11.7062 6.06025 11.6644 4.96539 10.9658 4.31252C9.94587 3.35934 8.90696 2.37814 7.82367 1.48389Z"
      fill="white"
    />
  </svg>
)

export default PhoneSolid
