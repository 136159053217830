import { Ref } from 'react'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { NavigationItem } from '@cerifi/contentful/__generated/graphql.types'
import { Container } from '@cerifi/ui/components/container'
import Link from '@cerifi/ui/components/link'

import { ChevronDownIcon } from '../../../icons'
import Affiliates from './affiliates'
import NavMenuCategory from './nav-menu-category'
import NavMenuFeaturedContent from './nav-menu-featured-content'

const NavMenuItem = ({
  navValue,
  sys,
  title,
  link,
  featured,
  navigationCategoriesCollection,
  affiliatesCollection,
  button,
  clickDisabled,
  triggerRefCallback,
}: NavigationItem & {
  navValue: string | undefined
  clickDisabled: boolean
  triggerRefCallback: Ref<HTMLButtonElement>
}) => {
  const navClasses =
    'flex items-center px-3 py-2 text-sm xl:text-lg leading-[25px]'

  const hasSubmenu =
    navigationCategoriesCollection?.items?.length ||
    affiliatesCollection?.items?.length

  if (hasSubmenu) {
    return (
      <NavigationMenu.Item value={sys?.id}>
        <NavigationMenu.Trigger
          ref={triggerRefCallback}
          onClick={(e) => {
            if (clickDisabled) e.preventDefault()
          }}
          className={`${navClasses} group justify-between gap-[0.25rem]`}
          data-testid="nav-category"
        >
          {title} <span className="sr-only">Navigation Sub-menu</span>
          <ChevronDownIcon
            width={20}
            height={20}
            className="duration-120 relative top-px -mr-2 transition-transform ease-in group-data-[state=open]:-rotate-180"
            aria-hidden
          />
        </NavigationMenu.Trigger>
        <NavigationMenu.Content
          forceMount
          hidden={navValue !== sys?.id}
          className="absolute left-0 top-0 w-full bg-[#f8f6f2] text-left will-change-transform"
        >
          <Container>
            <div className="flex w-full gap-4 px-8 py-6 xl:px-24 xl:py-10">
              {navigationCategoriesCollection?.items?.map(
                (category) =>
                  category && (
                    <NavMenuCategory
                      key={category.sys?.id}
                      category={category}
                    />
                  )
              )}
              {featured && <NavMenuFeaturedContent {...featured} />}
              {!!affiliatesCollection?.items?.length && (
                <Affiliates
                  affiliates={affiliatesCollection?.items}
                  button={button}
                />
              )}
            </div>
          </Container>
        </NavigationMenu.Content>
      </NavigationMenu.Item>
    )
  }

  if (link?.url && title) {
    return (
      <li>
        <Link className={navClasses} {...link}>
          {title}
        </Link>
      </li>
    )
  }

  return <li className={navClasses}>{title}</li>
}

export default NavMenuItem
