import { NavigationCategory } from '@cerifi/contentful/__generated/graphql.types'
import { cn } from '@cerifi/ui/utils/cn'

import NavMenuCategoryItem from './nav-menu-category-item'
import NavMenuHeading from './nav-menu-heading'

const NavMenuCategory = ({ category }: { category: NavigationCategory }) => {
  if (!category.itemsCollection?.items?.length) return null

  
  return (
    <div
      className={cn(
        'flex flex-col nav-cats',
        category.itemsCollection.items.length > 2 ? 'flex-[2]' : 'flex-1'
      )}
    >
      {category.title && <NavMenuHeading title={category.title} />}
      <ul
        className={cn(
          'grid gap-5',
          category.itemsCollection.items.length > 2 && 'grid-cols-2'
        )}
        style={{ gridTemplateRows: 'repeat(1, 1fr)' }}
      >
        {category.itemsCollection.items.map(
          (item,index) => item && !((category.title == 'CFP Exam Preparation' || category.title == 'CFP Continuing Education') && category.itemsCollection != null && index == (category.itemsCollection.items.length)-1 && index != 0 ) && 'Another Nav Item Example'&& <NavMenuCategoryItem key={item.sys?.id} {...item} />
        )}
        
        {/* SPFP Nav Item */}
        {category.title == 'CFP Exam Preparation' && category.itemsCollection.items.length > 1 &&
          <div className='Custom'>
            <div><div className="flex min-h-[1.625rem] items-center gap-3"><h2 className="font-sans uppercase leading-5 tracking-[0.125rem]">Other Programs</h2></div><div data-orientation="horizontal" role="none" className="h-px bg-neutral-300 mb-5 mt-2"></div></div>
            <ul
              className={cn(
                'grid',
                category.itemsCollection.items.length > 2 && 'grid-cols-2'
              )}
              style={{ gridTemplateRows: 'repeat(1, 1fr)' }}
            >
              {category.itemsCollection.items.map(
                (item,index) => item && category.itemsCollection != null && index == (category.itemsCollection.items.length)-1 && index != 0 && <NavMenuCategoryItem key={item.sys?.id} {...item} />
              )}
            </ul>
          </div>
          
        }

         {/* Second Nav Item */}
         {category.title == 'CFP Continuing Education' && category.itemsCollection.items.length > 1 &&
          <div>
            <div><div className="flex min-h-[1.625rem] items-center gap-3"><h2 className="font-sans uppercase leading-5 tracking-[0.125rem]"></h2></div><div data-orientation="horizontal" role="none" className="h-px bg-neutral-300 mb-5 mt-2"></div></div>
            <ul
              className={cn(
                'grid',
                category.itemsCollection.items.length > 2 && 'grid-cols-2'
              )}
              style={{ gridTemplateRows: 'repeat(1, 1fr)' }}
            >
              {category.itemsCollection.items.map(
                (item,index) => item &&  category.itemsCollection != null && index == (category.itemsCollection.items.length)-1 && index != 0  &&  <NavMenuCategoryItem key={item.sys?.id} {...item} />
              )}
            </ul>
          </div>
          
        }
      </ul>
      
    </div>
  )
}

export default NavMenuCategory
