import Image from 'next/image'
import Link from 'next/link'
import { Asset, Maybe } from '@cerifi/contentful/__generated/graphql.types'
import { IconButton } from '@cerifi/ui/components/icon-button'
import { MenuIcon } from '@cerifi/ui/components/icons'
import { cn } from '@cerifi/ui/utils/cn'

import PhoneTransparent from '../../icons/phone-transparent'
import { CartIcon } from '../cart-icon'

interface Props {
  className?: string
  openMobileNav: () => void
  logo: Maybe<Asset> | undefined
  storefrontName: Maybe<string> | undefined
  supportNumber: string
}

const MobileNavBar = ({
  className,
  openMobileNav,
  logo,
  storefrontName,
  supportNumber,
}: Props) => {
  return (
    <nav
      className={cn(
        'border-tan-350 box-border flex items-center gap-3 border-b p-3',
        className
      )}
    >
      <IconButton
        variant="ghost"
        noTooltip
        onClick={() => openMobileNav && openMobileNav()}
        aria-label="Mobile navigation menu"
      >
        <MenuIcon className="h-10" />
      </IconButton>
      <Link
        href="/"
        aria-label="home"
        className="flex-1"
        data-testid="mobile-home-link"
      >
        {logo?.url ? (
          <Image
            src={logo.url}
            alt={logo.description || storefrontName || 'Logo'}
            width="180"
            height="40"
            priority
          />
        ) : (
          <>{storefrontName}</>
        )}
      </Link>

      {supportNumber && (
        <Link
          href={`tel:${supportNumber}`}
          aria-label="button to call support"
          className="pr-3"
          suppressHydrationWarning
        >
          <PhoneTransparent />
        </Link>
      )}

      <CartIcon className="mr-3" />
    </nav>
  )
}

export default MobileNavBar
